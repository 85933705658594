import profile1 from '../assets/profile1.png'
import profile2 from '../assets/profile2.png'

export const TestimonialsData = [
    {
        image : profile1,
        comment: "Msika App is the best ecommerce website that i ever visited,its simple yet nice user interface and experience makes the whole process of purchasing an item easier",
        name: "Graca Mwalabu"
    },
    {
        image : profile2,
         comment: "Msika App is the best ecommerce website that i ever visited,its simple yet nice user interface and experience makes the whole process of purchasing an item easier",
        name: "Daisy Thomas"
    },
    {
        image : profile1,
         comment: "Msika App is the best ecommerce website that i ever visited,its simple yet nice user interface and experience makes the whole process of purchasing an item easier",
        name: "Setrida Unyolo"
    },
    {
        image : profile1,
         comment: "Msika App is the best ecommerce website that i ever visited,its simple yet nice user interface and experience makes the whole process of purchasing an item easier",
        name: "Nissie "
    },
    {
        image : profile2,
         comment: "Msika App is the best ecommerce website that i ever visited,its simple yet nice user interface and experience makes the whole process of purchasing an item easier",
        name: "Enerst"
    },
    {
        image : profile1,
         comment: "Msika App is the best ecommerce website that i ever visited,its simple yet nice user interface and experience makes the whole process of purchasing an item easier",
        name: "Richard"
    }
]